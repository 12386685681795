// to be completed

import {
    handleQueryResolve
} from '../utils'

export default function (firstDrug, secondDrug, range, startDate, endDate) {
  const where = []
  const attrs = []
  if (firstDrug) {
    // attrs = [{
    //   param: 'luCode',
    //   type: 'sql.Int',
    //   value: luCode,
    // }]

    // where = ['CODE = @luCode']
    this.first = `GenericName LIKE '%${firstDrug}%'`
  }
  if (secondDrug) {
    // attrs.push({
    //   param: 'DIN',
    //   type: 'sql.Int',
    //   value: DIN, // to support like
    // })

    this.second = `GenericName LIKE '%${secondDrug}%'`
  }
  if (range) {
    this.months = `Rx.RxDate > DATEADD(month, -${range}, GetDate())`
  }
  if (startDate) {
    // where.push(`Rx.RxDate > '${startDate}'`)
  }
  if (endDate) {
    // where.push(`Rx.RxDate < '${endDate}'`)
  }
  const query = `
  SELECT
  Rx.PatientID,
  LastName,
  FirstName
FROM
  Fillware.dbo.Rx
  INNER JOIN Fillware.dbo.DrugRoot ON Fillware.dbo.Rx.DIN = Fillware.dbo.DrugRoot.DIN
  INNER JOIN Fillware.dbo.Patient ON Fillware.dbo.Rx.PatientID = Fillware.dbo.Patient.PatientID
WHERE
${this.months}
AND ${this.first} 
AND Rx.PatientID in (SELECT
  Rx.PatientID
FROM
  Fillware.dbo.Rx
  INNER JOIN Fillware.dbo.DrugRoot ON Fillware.dbo.Rx.DIN = Fillware.dbo.DrugRoot.DIN
WHERE
${this.months}
  AND ${this.second} )

        `
  console.log(where)
  console.log(query)

  return this.query(query, attrs).then(handleQueryResolve)
}
